import $ from 'cash-dom';

export function NewGameFb() {
    event.preventDefault();
    event.stopImmediatePropagation();

    FB.login(function (response) {
        if (response.authResponse) {
            $.each(onFbLoad, function (i, o) {
                o();
            });
        } else {
            console.log('User cancelled login or did not fully authorize.');
        }
    }, {});

    var addFriends = function () {

        FB.api('/me/friends?fields=name,id,installed&limit=1000', function (response) {

            //$('#fb-friends').append('<div class="ffriend" style="width:100%;padding:2px 0 3px;">&nbsp;&nbsp;&nbsp;Search: <input id="friendSearch" type="text" onkeyup="updateFriends();" style="width:350px;padding:4px 0;" /></div><div class="ifriends"></div>');

            $.each(response.data, function (i, user) {
                var installed = user.installed || '';
                var addText = ''; // installed ? '&nbsp;&nbsp;(Plays Cycle Slam)' : '';
                var html = $('<div class="cell small-4 large-2">'
                    + '<img class="player__image" src="https://graph.facebook.com/' + user.id + '/picture?type=square" /></div>'
                    + '<div class="cell small-8 large-10 flex-container flex-dir-column">'
                    + '<div class="flex-child-shrink"><p><span class="fb-friends__name">' + user.name + '</span>' + addText + '</p></div>'
                    + '<div class="flex-child-shrink"><a class="request-fb-game button" href="' + (inCanvas ? '/Fb' : '') + '/Play/Facebook?vs=' + user.id + ' class="button">Challenge User</a></div>'
                    + '</div>');

                $('#fb-friends .fb-friends__list').append(html);
                /*
                if (installed) {
                    $('#fb-friends .fb-friends__list').prepend(html);
                } else {
                    $('#fb-friends .fb-friends__list').append(html);
                }*/
            });
        });
    };

    addFriends();

    return false;
}

export function RequestGameOnFb(id) {
    event.preventDefault();
    event.stopImmediatePropagation();

    var requestCallback = function (response) {
        var requestId = response.request;

        // TODO: Put this link in one single location, grab from A HREF?
        location.href = (inCanvas ? '/Fb' : '') + '/Play/Facebook?init=true&vs=' + id + '&request=' + requestId;
    };

    FB.login(function (response) {
        if (response.authResponse) {
            FB.ui({
                method: 'apprequests',
                message: 'Cycle Slam Challenge',
                to: id
            }, requestCallback);
        } else {
            console.log('User cancelled login or did not fully authorize.');
        }
    });

    return false;
}