import Cash from 'jquery'; // Really Cash from cash-dom
import tingle from 'tingle.js/dist/tingle';
import * as CsFb from './csfb';
import "../sass/index.scss";

var win = window;
win.jQuery = win.$ = Cash;
$(document).ready(function () {
    $('[data-open]').each(function (index, element) {
        $(element).on('click', function () {
            var content = $('#' + $(this).attr('data-open')).get(0);
            var modal = new tingle.modal();
            console.log(content);
            modal.setContent(content);
            modal.open();
        });
    });
    $('.start-fb-game').on('click', CsFb.NewGameFb);
    $('.request-fb-game').on('click', CsFb.RequestGameOnFb);
});
